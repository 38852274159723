export const useCareDayStore = defineStore("CareDayStore", {
  state: () => ({
    careDays: []
  }),
  getters: {
    getCareDayById: (state) => (id) => state.careDays.find((careDay) => careDay.id === id)
  },
  actions: {
    // setProperty (careDayId, propertyName, value) {
    //   const careDay = this.getCareDayById(careDayId);
    //   careDay[propertyName] = value;
    // },
    updateCareDays (facilityId) {
      const getCareDays = useNuxtApp().$getCareDays;

      getCareDays(facilityId).then((careDays) => {
        this.careDays = careDays;
      });
    },
    async getDocument(careDayId, collectionName, documentId) {
      try {
        const findDocument = useNuxtApp().$getDocumentById;
        const documentData = await findDocument(careDayId, collectionName, documentId);
        return documentData;
      } catch (error) {
        console.error('Error getting document:', error);
        throw error;
      }
    },
    getCollection (careDayId, collectionName, countSubCollectionNames) {
      const careDay = this.getCareDayById(careDayId);
      const getCareDayCollection = useNuxtApp().$getCareDayCollection;
      return getCareDayCollection(careDay.id, collectionName, countSubCollectionNames).then((items) => {
        careDay[collectionName] = items;
        return items;
      });
    },
    getSubCollection (careDayId, collectionName, itemId, subCollectionName) {
      const careDay = this.getCareDayById(careDayId);
      const getCareDayCollectionPath = useNuxtApp().$getCareDayCollectionPath;
      const path = [collectionName, itemId, subCollectionName];

      return getCareDayCollectionPath(careDay.id, path).then((items) => {
        const parentCollectionExists = !!careDay[collectionName];

        if (parentCollectionExists) {
          return addToCollectionData(careDay[collectionName], itemId, subCollectionName, items);
        } else {
          return this.getCollection(careDayId, collectionName, false).then(() => addToCollectionData(careDay[collectionName], itemId, subCollectionName, items));
        }
      });
    },

    getCareDayTeams (careDayId) {
      const getCareDayTeams = useNuxtApp().$getCareDayTeams;
      return getCareDayTeams(careDayId);
    },
    assignEmployeeToGroup (person, groupId, facilitiesDocId) {
      const assignEmployeeToGroup = useNuxtApp().$assignEmployeeToGroup;
      return assignEmployeeToGroup(person, groupId, facilitiesDocId);
    },
    removeEmployeeFromPool (teamMember, facilityId) {
      const removeEmployeeFromPool = useNuxtApp().$removeEmployeeFromPool;
      return removeEmployeeFromPool(teamMember, facilityId);
    },
    getSpecificContactTeam (careDayId, teamId) {
      const getSpecificContactTeam = useNuxtApp().$getSpecificContactTeam;
      return getSpecificContactTeam(careDayId, teamId);
    },
    removeEmployeeFromTeam (employeeId, teamId, facilityId) {
      const removeEmployeeFromTeam = useNuxtApp().$removeEmployeeFromTeam;
      return removeEmployeeFromTeam(employeeId, teamId, facilityId);
    },
    getEmployees (facilityId) {
      const getEmployees = useNuxtApp().$getEmployees;
      return getEmployees(facilityId);
    },
    removeEmployee (employee, facilityId) {
      const removeEmployee = useNuxtApp().$removeEmployee;
      return removeEmployee(employee, facilityId);
    }
  }
});

const addToCollectionData = (collectionData, parentItemId, subCollectionName, items) => {
  if (!collectionData) {
    return null;
  }

  const item = collectionData.find((item) => item.id === parentItemId);

  if (!item) {
    collectionData.push({
      id: parentItemId,
      [subCollectionName]: items
    });
  } else {
    item[subCollectionName] = items;
  }

  return items;
};
