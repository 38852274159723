import revive_payload_client_4sVQNw7RlN from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/circleci/project/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/circleci/project/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/circleci/project/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/home/circleci/project/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/circleci/project/.nuxt/sentry-client-config.mjs";
import composition_sLxaNGmlSL from "/home/circleci/project/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/home/circleci/project/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/circleci/project/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import _1_firebaseAuth_client_RCkgV6rM81 from "/home/circleci/project/plugins/1.firebaseAuth.client.js";
import _3_data_client_EqGijOhQu3 from "/home/circleci/project/plugins/3.data.client.js";
import bootstrap_client_zGQC9AE5yr from "/home/circleci/project/plugins/bootstrap.client.js";
import clickOutside_C8jIyDRU4O from "/home/circleci/project/plugins/clickOutside.js";
import fontawesome_klhsrycjcK from "/home/circleci/project/plugins/fontawesome.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  _1_firebaseAuth_client_RCkgV6rM81,
  _3_data_client_EqGijOhQu3,
  bootstrap_client_zGQC9AE5yr,
  clickOutside_C8jIyDRU4O,
  fontawesome_klhsrycjcK
]