import validate from "/home/circleci/project/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45kiosk_45id_45token_45global from "/home/circleci/project/middleware/1.kioskIdToken.global.ts";
import _2_45auth_45global from "/home/circleci/project/middleware/2.auth.global.ts";
import _3_45admin_45global from "/home/circleci/project/middleware/3.admin.global.ts";
import current_45care_45day_45global from "/home/circleci/project/middleware/currentCareDay.global.js";
import manifest_45route_45rule from "/home/circleci/project/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45kiosk_45id_45token_45global,
  _2_45auth_45global,
  _3_45admin_45global,
  current_45care_45day_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}