export const useFacilityStore = defineStore("FacilityStore", {
  state: () => ({
    facility: null,
    food: null,
    activityTemplates: null,
    careDays: null,
    employees: null,
    foodTemplates: null
  }),
  actions: {
    updateFacility: (facilityId) => {
      const getFacility = useNuxtApp().$getFacility;

      getFacility(facilityId)
        .then(storeFacility)
        .then(triggerCareDayUpdate);
    },
    getEmployees () {
      if (!this.facility) {
        const errorMessage = "No facility set";
        return Promise.reject(new Error(errorMessage));
      }

      const getEmployees = useNuxtApp().$getEmployees;
      return getEmployees(this.facility.id)
        .then((employees) => {
          this.employees = employees;
        });
    },
    getEmployeesByContactTeam (teamId) {
      const getEmployeesByContactTeam = useNuxtApp().$getEmployeesByContactTeam;
      return getEmployeesByContactTeam(teamId);
    },
    getEmployeesCountByContactTeam (teamId) {
      const getEmployeesCountByContactTeam = useNuxtApp().$getEmployeesCountByContactTeam;
      return getEmployeesCountByContactTeam(teamId);
    },
    getFood () {
      if (!this.facility) {
        const errorMessage = "No facility set";
        return Promise.reject(new Error(errorMessage));
      }

      const getFood = useNuxtApp().$getFood;
      return getFood(this.facility.id)
        .then((food) => {
          this.food = food;
        });
    },
    getFoodTemplates () {
      if (!this.facility) {
        const errorMessage = "No facility set";
        return Promise.reject(new Error(errorMessage));
      }

      const getFoodTemplates = useNuxtApp().$getFoodTemplates;
      return getFoodTemplates(this.facility.id)
        .then((foodTemplates) => {
          this.foodTemplates = foodTemplates;
          return this.foodTemplates;
        });
    },
    getActivityTemplates () {
      if (!this.facility) {
        const errorMessage = "No facility set";
        return Promise.reject(new Error(errorMessage));
      }

      const getActivityTemplates = useNuxtApp().$getActivityTemplates;
      return getActivityTemplates(this.facility.id)
        .then((activityTemplates) => {
          this.activityTemplates = activityTemplates;
          return this.activityTemplates;
        });
    },
    getCareDays () {
      if (!this.facility) {
        const errorMessage = "No facility set";
        return Promise.reject(new Error(errorMessage));
      }

      const getCareDays = useNuxtApp().$getCareDays;
      return getCareDays(this.facility.id)
        .then((careDaysResult) => {
          this.careDays = careDaysResult;
          return this.careDays;
        });
    }
  }
});

export const storeFacility = (facility) => {
  const facilityStore = useFacilityStore();
  facilityStore.facility = facility;
};

const triggerCareDayUpdate = () => {
  const facilityStore = useFacilityStore();
  const careDayStore = useCareDayStore();
  careDayStore.updateCareDays(facilityStore.facility.id);
};
