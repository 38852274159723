import resetAllStores from "@/stores/util/reset";
import { setSentryStoredUser } from "~/composables/useSentry.js";

export const useUserStore = defineStore("UserStore", {
  state: () => ({
    user: null
  }),
  getters: {
    isAlreadyStored: (state) => state.user !== null,
    isSameUser: (state) => (userId) => !!state.user && state.user.id === userId
  },
  actions: {
    getUserFromDb () {
      const firebaseUser = useFirebaseUser().value;
      const getUser = useNuxtApp().$getUser;

      if (firebaseUser) {
        // user is already stored so no need to fetch it again
        if (this.isAlreadyStored && this.isSameUser(firebaseUser.uid)) {
          return;
        }

        getUser(firebaseUser.uid)
          .then(storeUser)
          .then(triggerFacilityUpdate);
      } else {
        resetAllStores();
      }
    }
  }
});

export const storeUser = (user) => {
  const userStore = useUserStore();
  userStore.user = user;

  setSentryStoredUser(user);
};

const triggerFacilityUpdate = () => {
  const userStore = useUserStore();
  const facilityStore = useFacilityStore();
  facilityStore.updateFacility(userStore.user.facility.id);
};
