import { User } from "firebase/auth";
import { Ref } from "vue";

const CARE_TABLE_EMAIL_DOMAINS = ["@caretable.de", "@senexis.de"];

// TODO: check if custom claims are a better alternative
export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith("/admin")) {
    const user: Ref<User | null> = useFirebaseUser();

    if (!user.value) {
      return navigateTo("/login");
    }

    const email = user.value?.email;
    const isCareTableTeam = CARE_TABLE_EMAIL_DOMAINS.some((domain) => email?.endsWith(domain));

    // TODO: change it back
    // const hasVerifiedEmail = user.value?.emailVerified;
    const hasVerifiedEmail = true;

    if (!isCareTableTeam || !hasVerifiedEmail) {
      return navigateTo("/");
    }
  }
});
