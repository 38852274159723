<script setup>
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/main.css";

const props = defineProps({
  error: Object
});

const { t } = useI18n();

const errorMessage = computed(() => {
  if (props.error.statusCode === 404) {
    return t("errors.page_not_found");
  }

  return props.error.message;
});

const router = useRouter();

const handleError = () => {
  clearError();
  router.back();
};
</script>

<template>
  <div
    id="modal"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-modal="true"
    style="display: block;"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title cb_tiny_on_mobile">
            {{ props.error.statusCode }}  {{ errorMessage }}
          </h3>
        </div>

        <div class="modal-body">
          <slot />
        </div>

        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-footer cb_tiny_on_mobile"
            @click="handleError"
            @touchend="handleError"
          >
            {{ $t("button.back") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-content{
  height: 50vh;
  margin-top: 4rem !important;
}

.modal-header{
  color: var(--ct_B1)!important;
  background-color: var(--ct_E1);
  justify-content: center;
}

.modal-body{
  background: url("assets/graphics/icons/error-camilla.svg") no-repeat;
  background-size: cover;
}
.error-img{
  width: 80%;
}

.modal-footer{
  border: none;
  background-color: var(--ct_D1)!important;
}

.btn-footer{
    display: inline-block;
    width: 100%;
    height: 5rem;
    font-weight: 500;
    color: var(--ct_A);
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: .375rem .75rem;
    font-size: 1.5em;
    line-height: 1.5;
}
</style>
