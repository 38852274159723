import { default as indexUr7fBOpIYgMeta } from "/home/circleci/project/pages/admin/caredays/index.vue?macro=true";
import { default as newJ7D7HTgX93Meta } from "/home/circleci/project/pages/admin/caredays/new.vue?macro=true";
import { default as indexVYxpFkjPIdMeta } from "/home/circleci/project/pages/admin/facilities/index.vue?macro=true";
import { default as newG6VvskABVkMeta } from "/home/circleci/project/pages/admin/facilities/new.vue?macro=true";
import { default as indexx7YPFNgbKRMeta } from "/home/circleci/project/pages/admin/index.vue?macro=true";
import { default as indexYbPn4GiyXNMeta } from "/home/circleci/project/pages/admin/organizations/index.vue?macro=true";
import { default as newbZwUVwidHNMeta } from "/home/circleci/project/pages/admin/organizations/new.vue?macro=true";
import { default as indextgq3eZflKOMeta } from "/home/circleci/project/pages/admin/users/index.vue?macro=true";
import { default as newqM805IH4WHMeta } from "/home/circleci/project/pages/admin/users/new.vue?macro=true";
import { default as indexYh4cllr2TqMeta } from "/home/circleci/project/pages/careday/[id]/accommodation/[accommodationId]/index.vue?macro=true";
import { default as indexKQyclueLjIMeta } from "/home/circleci/project/pages/careday/[id]/accommodation/index.vue?macro=true";
import { default as indexMY9vl0XCBCMeta } from "/home/circleci/project/pages/careday/[id]/activities/index.vue?macro=true";
import { default as indexlezL3L4vn3Meta } from "/home/circleci/project/pages/careday/[id]/contactteam/index.vue?macro=true";
import { default as detailEYvDnEMYWSMeta } from "/home/circleci/project/pages/careday/[id]/detail.vue?macro=true";
import { default as indexGnIJIstDrSMeta } from "/home/circleci/project/pages/careday/[id]/imagegallery/[galleryId]/index.vue?macro=true";
import { default as indexrnFUOesqV2Meta } from "/home/circleci/project/pages/careday/[id]/imagegallery/index.vue?macro=true";
import { default as indexgUzPg7oUEsMeta } from "/home/circleci/project/pages/careday/[id]/menu/index.vue?macro=true";
import { default as indexV2ojX2wnotMeta } from "/home/circleci/project/pages/careday/[id]/news/index.vue?macro=true";
import { default as index4IFnGWhzlLMeta } from "/home/circleci/project/pages/careday/[id]/openingtimes/index.vue?macro=true";
import { default as indexbVK8ERtDnaMeta } from "/home/circleci/project/pages/index.vue?macro=true";
import { default as indexjRhjIouhr2Meta } from "/home/circleci/project/pages/kiosk/[id]/accommodation/[accommodationId]/index.vue?macro=true";
import { default as indexroof28MVmzMeta } from "/home/circleci/project/pages/kiosk/[id]/accommodation/index.vue?macro=true";
import { default as indexAh7cTSTI5eMeta } from "/home/circleci/project/pages/kiosk/[id]/activities/index.vue?macro=true";
import { default as indexsnSUlF6hMCMeta } from "/home/circleci/project/pages/kiosk/[id]/contactteam/[teamId]/index.vue?macro=true";
import { default as indexsvTSSSvmfxMeta } from "/home/circleci/project/pages/kiosk/[id]/contactteam/index.vue?macro=true";
import { default as indexXNZ2rTyrZgMeta } from "/home/circleci/project/pages/kiosk/[id]/imagegallery/[galleryId]/index.vue?macro=true";
import { default as indexa38h7pMaRNMeta } from "/home/circleci/project/pages/kiosk/[id]/imagegallery/index.vue?macro=true";
import { default as indexqLtCNLFjjQMeta } from "/home/circleci/project/pages/kiosk/[id]/index.vue?macro=true";
import { default as indexFWni94UvZsMeta } from "/home/circleci/project/pages/kiosk/[id]/menu/index.vue?macro=true";
import { default as indexspUQjbb7PhMeta } from "/home/circleci/project/pages/kiosk/[id]/news/[newsId]/index.vue?macro=true";
import { default as indexyOu9ShA0RyMeta } from "/home/circleci/project/pages/kiosk/[id]/news/index.vue?macro=true";
import { default as indexoOoZf0wPASMeta } from "/home/circleci/project/pages/kiosk/[id]/openingtimes/index.vue?macro=true";
import { default as indexiSRW5aQ2yvMeta } from "/home/circleci/project/pages/kiosk/index.vue?macro=true";
import { default as loginP848kCqXQfMeta } from "/home/circleci/project/pages/kiosk/login.vue?macro=true";
import { default as logint64qfb6BCpMeta } from "/home/circleci/project/pages/login.vue?macro=true";
import { default as manualsqzhzBdmGh7Meta } from "/home/circleci/project/pages/manuals.vue?macro=true";
import { default as newsD4vJfqFGpxMeta } from "/home/circleci/project/pages/news.vue?macro=true";
import { default as settingspvr6NiIcDWMeta } from "/home/circleci/project/pages/settings.vue?macro=true";
export default [
  {
    name: indexUr7fBOpIYgMeta?.name ?? "admin-caredays",
    path: indexUr7fBOpIYgMeta?.path ?? "/admin/caredays",
    meta: indexUr7fBOpIYgMeta || {},
    alias: indexUr7fBOpIYgMeta?.alias || [],
    redirect: indexUr7fBOpIYgMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/caredays/index.vue").then(m => m.default || m)
  },
  {
    name: newJ7D7HTgX93Meta?.name ?? "admin-caredays-new",
    path: newJ7D7HTgX93Meta?.path ?? "/admin/caredays/new",
    meta: newJ7D7HTgX93Meta || {},
    alias: newJ7D7HTgX93Meta?.alias || [],
    redirect: newJ7D7HTgX93Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/caredays/new.vue").then(m => m.default || m)
  },
  {
    name: indexVYxpFkjPIdMeta?.name ?? "admin-facilities",
    path: indexVYxpFkjPIdMeta?.path ?? "/admin/facilities",
    meta: indexVYxpFkjPIdMeta || {},
    alias: indexVYxpFkjPIdMeta?.alias || [],
    redirect: indexVYxpFkjPIdMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/facilities/index.vue").then(m => m.default || m)
  },
  {
    name: newG6VvskABVkMeta?.name ?? "admin-facilities-new",
    path: newG6VvskABVkMeta?.path ?? "/admin/facilities/new",
    meta: newG6VvskABVkMeta || {},
    alias: newG6VvskABVkMeta?.alias || [],
    redirect: newG6VvskABVkMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/facilities/new.vue").then(m => m.default || m)
  },
  {
    name: indexx7YPFNgbKRMeta?.name ?? "admin",
    path: indexx7YPFNgbKRMeta?.path ?? "/admin",
    meta: indexx7YPFNgbKRMeta || {},
    alias: indexx7YPFNgbKRMeta?.alias || [],
    redirect: indexx7YPFNgbKRMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexYbPn4GiyXNMeta?.name ?? "admin-organizations",
    path: indexYbPn4GiyXNMeta?.path ?? "/admin/organizations",
    meta: indexYbPn4GiyXNMeta || {},
    alias: indexYbPn4GiyXNMeta?.alias || [],
    redirect: indexYbPn4GiyXNMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: newbZwUVwidHNMeta?.name ?? "admin-organizations-new",
    path: newbZwUVwidHNMeta?.path ?? "/admin/organizations/new",
    meta: newbZwUVwidHNMeta || {},
    alias: newbZwUVwidHNMeta?.alias || [],
    redirect: newbZwUVwidHNMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/organizations/new.vue").then(m => m.default || m)
  },
  {
    name: indextgq3eZflKOMeta?.name ?? "admin-users",
    path: indextgq3eZflKOMeta?.path ?? "/admin/users",
    meta: indextgq3eZflKOMeta || {},
    alias: indextgq3eZflKOMeta?.alias || [],
    redirect: indextgq3eZflKOMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: newqM805IH4WHMeta?.name ?? "admin-users-new",
    path: newqM805IH4WHMeta?.path ?? "/admin/users/new",
    meta: newqM805IH4WHMeta || {},
    alias: newqM805IH4WHMeta?.alias || [],
    redirect: newqM805IH4WHMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/admin/users/new.vue").then(m => m.default || m)
  },
  {
    name: indexYh4cllr2TqMeta?.name ?? "careday-id-accommodation-accommodationId",
    path: indexYh4cllr2TqMeta?.path ?? "/careday/:id()/accommodation/:accommodationId()",
    meta: indexYh4cllr2TqMeta || {},
    alias: indexYh4cllr2TqMeta?.alias || [],
    redirect: indexYh4cllr2TqMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/accommodation/[accommodationId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexKQyclueLjIMeta?.name ?? "careday-id-accommodation",
    path: indexKQyclueLjIMeta?.path ?? "/careday/:id()/accommodation",
    meta: indexKQyclueLjIMeta || {},
    alias: indexKQyclueLjIMeta?.alias || [],
    redirect: indexKQyclueLjIMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/accommodation/index.vue").then(m => m.default || m)
  },
  {
    name: indexMY9vl0XCBCMeta?.name ?? "careday-id-activities",
    path: indexMY9vl0XCBCMeta?.path ?? "/careday/:id()/activities",
    meta: indexMY9vl0XCBCMeta || {},
    alias: indexMY9vl0XCBCMeta?.alias || [],
    redirect: indexMY9vl0XCBCMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/activities/index.vue").then(m => m.default || m)
  },
  {
    name: indexlezL3L4vn3Meta?.name ?? "careday-id-contactteam",
    path: indexlezL3L4vn3Meta?.path ?? "/careday/:id()/contactteam",
    meta: indexlezL3L4vn3Meta || {},
    alias: indexlezL3L4vn3Meta?.alias || [],
    redirect: indexlezL3L4vn3Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/contactteam/index.vue").then(m => m.default || m)
  },
  {
    name: detailEYvDnEMYWSMeta?.name ?? "careday-id-detail",
    path: detailEYvDnEMYWSMeta?.path ?? "/careday/:id()/detail",
    meta: detailEYvDnEMYWSMeta || {},
    alias: detailEYvDnEMYWSMeta?.alias || [],
    redirect: detailEYvDnEMYWSMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/detail.vue").then(m => m.default || m)
  },
  {
    name: indexGnIJIstDrSMeta?.name ?? "careday-id-imagegallery-galleryId",
    path: indexGnIJIstDrSMeta?.path ?? "/careday/:id()/imagegallery/:galleryId()",
    meta: indexGnIJIstDrSMeta || {},
    alias: indexGnIJIstDrSMeta?.alias || [],
    redirect: indexGnIJIstDrSMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/imagegallery/[galleryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrnFUOesqV2Meta?.name ?? "careday-id-imagegallery",
    path: indexrnFUOesqV2Meta?.path ?? "/careday/:id()/imagegallery",
    meta: indexrnFUOesqV2Meta || {},
    alias: indexrnFUOesqV2Meta?.alias || [],
    redirect: indexrnFUOesqV2Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/imagegallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexgUzPg7oUEsMeta?.name ?? "careday-id-menu",
    path: indexgUzPg7oUEsMeta?.path ?? "/careday/:id()/menu",
    meta: indexgUzPg7oUEsMeta || {},
    alias: indexgUzPg7oUEsMeta?.alias || [],
    redirect: indexgUzPg7oUEsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/menu/index.vue").then(m => m.default || m)
  },
  {
    name: indexV2ojX2wnotMeta?.name ?? "careday-id-news",
    path: indexV2ojX2wnotMeta?.path ?? "/careday/:id()/news",
    meta: indexV2ojX2wnotMeta || {},
    alias: indexV2ojX2wnotMeta?.alias || [],
    redirect: indexV2ojX2wnotMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/news/index.vue").then(m => m.default || m)
  },
  {
    name: index4IFnGWhzlLMeta?.name ?? "careday-id-openingtimes",
    path: index4IFnGWhzlLMeta?.path ?? "/careday/:id()/openingtimes",
    meta: index4IFnGWhzlLMeta || {},
    alias: index4IFnGWhzlLMeta?.alias || [],
    redirect: index4IFnGWhzlLMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/careday/[id]/openingtimes/index.vue").then(m => m.default || m)
  },
  {
    name: indexbVK8ERtDnaMeta?.name ?? "index",
    path: indexbVK8ERtDnaMeta?.path ?? "/",
    meta: indexbVK8ERtDnaMeta || {},
    alias: indexbVK8ERtDnaMeta?.alias || [],
    redirect: indexbVK8ERtDnaMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexjRhjIouhr2Meta?.name ?? "kiosk-id-accommodation-accommodationId",
    path: indexjRhjIouhr2Meta?.path ?? "/kiosk/:id()/accommodation/:accommodationId()",
    meta: indexjRhjIouhr2Meta || {},
    alias: indexjRhjIouhr2Meta?.alias || [],
    redirect: indexjRhjIouhr2Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/accommodation/[accommodationId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexroof28MVmzMeta?.name ?? "kiosk-id-accommodation",
    path: indexroof28MVmzMeta?.path ?? "/kiosk/:id()/accommodation",
    meta: indexroof28MVmzMeta || {},
    alias: indexroof28MVmzMeta?.alias || [],
    redirect: indexroof28MVmzMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/accommodation/index.vue").then(m => m.default || m)
  },
  {
    name: indexAh7cTSTI5eMeta?.name ?? "kiosk-id-activities",
    path: indexAh7cTSTI5eMeta?.path ?? "/kiosk/:id()/activities",
    meta: indexAh7cTSTI5eMeta || {},
    alias: indexAh7cTSTI5eMeta?.alias || [],
    redirect: indexAh7cTSTI5eMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/activities/index.vue").then(m => m.default || m)
  },
  {
    name: indexsnSUlF6hMCMeta?.name ?? "kiosk-id-contactteam-teamId",
    path: indexsnSUlF6hMCMeta?.path ?? "/kiosk/:id()/contactteam/:teamId()",
    meta: indexsnSUlF6hMCMeta || {},
    alias: indexsnSUlF6hMCMeta?.alias || [],
    redirect: indexsnSUlF6hMCMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/contactteam/[teamId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsvTSSSvmfxMeta?.name ?? "kiosk-id-contactteam",
    path: indexsvTSSSvmfxMeta?.path ?? "/kiosk/:id()/contactteam",
    meta: indexsvTSSSvmfxMeta || {},
    alias: indexsvTSSSvmfxMeta?.alias || [],
    redirect: indexsvTSSSvmfxMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/contactteam/index.vue").then(m => m.default || m)
  },
  {
    name: indexXNZ2rTyrZgMeta?.name ?? "kiosk-id-imagegallery-galleryId",
    path: indexXNZ2rTyrZgMeta?.path ?? "/kiosk/:id()/imagegallery/:galleryId()",
    meta: indexXNZ2rTyrZgMeta || {},
    alias: indexXNZ2rTyrZgMeta?.alias || [],
    redirect: indexXNZ2rTyrZgMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/imagegallery/[galleryId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexa38h7pMaRNMeta?.name ?? "kiosk-id-imagegallery",
    path: indexa38h7pMaRNMeta?.path ?? "/kiosk/:id()/imagegallery",
    meta: indexa38h7pMaRNMeta || {},
    alias: indexa38h7pMaRNMeta?.alias || [],
    redirect: indexa38h7pMaRNMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/imagegallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexqLtCNLFjjQMeta?.name ?? "kiosk-id",
    path: indexqLtCNLFjjQMeta?.path ?? "/kiosk/:id()",
    meta: indexqLtCNLFjjQMeta || {},
    alias: indexqLtCNLFjjQMeta?.alias || [],
    redirect: indexqLtCNLFjjQMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexFWni94UvZsMeta?.name ?? "kiosk-id-menu",
    path: indexFWni94UvZsMeta?.path ?? "/kiosk/:id()/menu",
    meta: indexFWni94UvZsMeta || {},
    alias: indexFWni94UvZsMeta?.alias || [],
    redirect: indexFWni94UvZsMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/menu/index.vue").then(m => m.default || m)
  },
  {
    name: indexspUQjbb7PhMeta?.name ?? "kiosk-id-news-newsId",
    path: indexspUQjbb7PhMeta?.path ?? "/kiosk/:id()/news/:newsId()",
    meta: indexspUQjbb7PhMeta || {},
    alias: indexspUQjbb7PhMeta?.alias || [],
    redirect: indexspUQjbb7PhMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/news/[newsId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexyOu9ShA0RyMeta?.name ?? "kiosk-id-news",
    path: indexyOu9ShA0RyMeta?.path ?? "/kiosk/:id()/news",
    meta: indexyOu9ShA0RyMeta || {},
    alias: indexyOu9ShA0RyMeta?.alias || [],
    redirect: indexyOu9ShA0RyMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/news/index.vue").then(m => m.default || m)
  },
  {
    name: indexoOoZf0wPASMeta?.name ?? "kiosk-id-openingtimes",
    path: indexoOoZf0wPASMeta?.path ?? "/kiosk/:id()/openingtimes",
    meta: indexoOoZf0wPASMeta || {},
    alias: indexoOoZf0wPASMeta?.alias || [],
    redirect: indexoOoZf0wPASMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/[id]/openingtimes/index.vue").then(m => m.default || m)
  },
  {
    name: indexiSRW5aQ2yvMeta?.name ?? "kiosk",
    path: indexiSRW5aQ2yvMeta?.path ?? "/kiosk",
    meta: indexiSRW5aQ2yvMeta || {},
    alias: indexiSRW5aQ2yvMeta?.alias || [],
    redirect: indexiSRW5aQ2yvMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/index.vue").then(m => m.default || m)
  },
  {
    name: loginP848kCqXQfMeta?.name ?? "kiosk-login",
    path: loginP848kCqXQfMeta?.path ?? "/kiosk/login",
    meta: loginP848kCqXQfMeta || {},
    alias: loginP848kCqXQfMeta?.alias || [],
    redirect: loginP848kCqXQfMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/kiosk/login.vue").then(m => m.default || m)
  },
  {
    name: logint64qfb6BCpMeta?.name ?? "login",
    path: logint64qfb6BCpMeta?.path ?? "/login",
    meta: logint64qfb6BCpMeta || {},
    alias: logint64qfb6BCpMeta?.alias || [],
    redirect: logint64qfb6BCpMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/login.vue").then(m => m.default || m)
  },
  {
    name: manualsqzhzBdmGh7Meta?.name ?? "manuals",
    path: manualsqzhzBdmGh7Meta?.path ?? "/manuals",
    meta: manualsqzhzBdmGh7Meta || {},
    alias: manualsqzhzBdmGh7Meta?.alias || [],
    redirect: manualsqzhzBdmGh7Meta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/manuals.vue").then(m => m.default || m)
  },
  {
    name: newsD4vJfqFGpxMeta?.name ?? "news",
    path: newsD4vJfqFGpxMeta?.path ?? "/news",
    meta: newsD4vJfqFGpxMeta || {},
    alias: newsD4vJfqFGpxMeta?.alias || [],
    redirect: newsD4vJfqFGpxMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/news.vue").then(m => m.default || m)
  },
  {
    name: settingspvr6NiIcDWMeta?.name ?? "settings",
    path: settingspvr6NiIcDWMeta?.path ?? "/settings",
    meta: settingspvr6NiIcDWMeta || {},
    alias: settingspvr6NiIcDWMeta?.alias || [],
    redirect: settingspvr6NiIcDWMeta?.redirect || undefined,
    component: () => import("/home/circleci/project/pages/settings.vue").then(m => m.default || m)
  }
]