import { initializeApp } from "firebase/app";
import {
  getAuth
} from "firebase/auth";
import { initializeFirestore, persistentLocalCache, CACHE_SIZE_UNLIMITED } from "firebase/firestore";
import { getStorage } from "firebase/storage";

export default defineNuxtPlugin(() => {
  const {
    FIREBASE_API_KEY: apiKey,
    FIREBASE_AUTH_DOMAIN: authDomain,
    FIREBASE_PROJECT_ID: projectId,
    IMAGE_BUCKET
  } = useRuntimeConfig().public;

  const imageBucket = `gs://${IMAGE_BUCKET}`;

  const firebaseConfig = {
    apiKey,
    authDomain,
    projectId
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig, "clientApp");

  const auth = getAuth(app);
  const firestore = initializeFirestore(app, {
    localCache: persistentLocalCache({
      cacheSizeBytes: CACHE_SIZE_UNLIMITED
    })
  });
  const imageStorage = getStorage(app, imageBucket);

  return {
    provide: {
      auth,
      firestore,
      imageStorage
    }
  };
});
