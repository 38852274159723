
import { decodeJwt } from "jose";

export default defineNuxtRouteMiddleware((to) => {
  const isKioskIndexPage = to.path === "/kiosk" || to.path === "/kiosk/";

  if (isKioskIndexPage) {
    const token = useRoute().query.token as string;

    if (!!token && token.trim() !== "") {
      const { careDayId } = decodeJwt(token) as { careDayId: string };

      if (careDayId) {
        return navigateTo({
          path: `/kiosk/${careDayId}`,
          query: {
            token
          }
        });
      }
    }

    return navigateTo("/kiosk/login");
  }
});
