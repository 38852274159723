import * as Sentry from "@sentry/browser";

export const setSentryFirebaseUser = (user) => {
  const { uid: userId, email: userEmail } = user;

  Sentry.setUser({
    id: userId,
    email: userEmail
  });
};

export const setSentryStoredUser = (user) => {
  const firebaseUser = useFirebaseUser().value;

  const { uid: userId, email: userEmail } = firebaseUser;
  const { id: facilityId, role: facilityRole } = user.facility;

  const sentryUser = {
    id: userId,
    email: userEmail,
    facilityId,
    facilityRole
  };

  Sentry.setUser(sentryUser);

  Sentry.setTag("facilityId", facilityId);
};

export const unsetSentryUser = () => {
  Sentry.setUser(null);
  Sentry.setTag("facilityId", null);
};

export const setSentryCareDayID = (careDayId) => {
  Sentry.setTag("careDayId", careDayId);
  Sentry.setTag("clientType", "kiosk");
};

export const unsetSentryCareDayID = () => {
  Sentry.setTag("careDayId", null);
  Sentry.setTag("clientType", null);
};
