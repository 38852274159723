import { de, enGB, enUS } from "date-fns/locale";
import { Settings } from "luxon";

export const useNavBrowserLocale = () => {
  if (navigator.languages !== undefined) {
    return navigator.languages[0];
  } else if (navigator.language !== undefined) {
    return navigator.language;
  }

  return "de";
};

export const useLocale = () => useState("locale", useNavBrowserLocale);

export const setAppLocale = () => {
  const { setLocale } = useI18n();

  Settings.defaultLocale = useLocale().value;
  setLocale(useLocale().value);
};

export const getDateFNSLocale = (localeCode) => {
  switch (localeCode) {
    case "de":
    case "de-DE":
    case "de-AT":
    case "de-CH":
      return de;
    case "en":
    case "en-GB":
      return enGB;
    case "en-US":
      return enUS;
    default:
      return de;
  }
};

export const useDateFormat = (localeCode) => {
  switch (localeCode) {
    case "de":
    case "de-DE":
    case "de-AT":
    case "de-CH":
      return "dd.MM.yyyy";
    case "en":
    case "en-GB":
    case "en-US":
      return "MM/dd/yyyy";
    default:
      return "yyyy-MM-dd";
  }
};
