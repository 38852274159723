import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

export default defineNuxtPlugin((nuxtApp) => {
  const {
    CARE_DAY_DB: careDayDatabase,
    FACILITY_DB: facilityDatabase,
    USER_DB: userDatabase,
  } = useRuntimeConfig().public;

  const firestore = nuxtApp.$firestore;

  const getUser = async (userId) => {
    const docRef = doc(firestore, userDatabase, userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        id: docSnap.id,
        ...docSnap.data(),
      };
    } else {
      return null;
    }
  };

  const getFacility = async (facilityId) => {
    const docRef = doc(firestore, facilityDatabase, facilityId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return {
        id: docSnap.id,
        ...docSnap.data(),
      };
    } else {
      return null;
    }
  };

  const CD_SUB_COLLECTION_NAMES = [
    "accommodation",
    "activities",
    "contactTeams",
    "imageGalleries",
    "meals",
    "menu",
    "news",
    "openingTimes",
  ];

  const getEmployees = async (facilityId) => {
    const employeesColRef = collection(
      firestore,
      facilityDatabase,
      facilityId,
      "employees"
    );
    return await getDocs(employeesColRef).then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().name,
        phoneNumber: doc.data().phoneNumber,
        mobileNumber: doc.data().mobileNumber,
        function: doc.data().function,
        priority: doc.data().priority,
        ...doc.data(),
      }))
    );
  };

  const getCareDayTeams = async (careDayId) => {
    const careDayTeamsCollection = collection(
      firestore,
      careDayDatabase,
      careDayId,
      "contactTeams"
    );
    return await getDocs(careDayTeamsCollection).then((querySnapshot) =>
      querySnapshot.docs.map((doc) => {
        const docData = doc.data();
        return {
          id: doc.id,
          ...docData,
        };
      })
    );
  };
  const removeEmployee = (employee, facilityId) => {
    const employeesColRef = collection(
      firestore,
      facilityDatabase,
      facilityId,
      "employees"
    );
    const employeeDocRef = doc(employeesColRef, employee.id);
    return deleteDoc(employeeDocRef);
  };

  const assignEmployeeToGroup = async (employeeId, groupId, facilityId) => {
    const employeeDoc = await getDoc(
      doc(firestore, facilityDatabase, facilityId, "employees", employeeId)
    );

    if (employeeDoc && employeeDoc.exists) {
      await updateDoc(employeeDoc.ref, {
        contactTeamIds: arrayUnion(groupId),
      });
    }
  };

  const addEmployee = async (employee, facilityId) => {
    delete employee.id;
    delete employee.imageUrl;

    const employeesCollectionRef = collection(
      firestore,
      facilityDatabase,
      facilityId,
      "employees"
    );
    return await addDoc(employeesCollectionRef, employee).then(
      (docRef) => docRef.id
    );
  };

  const removeEmployeeFromPool = (employee, facilityId) => {
    const employeesCollectionRef = collection(
      firestore,
      facilityDatabase,
      facilityId,
      "employees"
    );
    return getDocs(employeesCollectionRef)
      .then((employeesQuerySnapshot) => {
        for (const employeeDoc of employeesQuerySnapshot.docs) {
          if (employeeDoc.data().name === employee.name) {
            const documentId = employeeDoc.id;
            const employeeDocRef = doc(
              firestore,
              facilityDatabase,
              facilityId,
              "employees",
              documentId
            );
            return deleteDoc(employeeDocRef);
          }
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const getCareDays = async (facilityId) => {
    const careDaysCollection = collection(firestore, careDayDatabase);
    const careDaysQuery = query(
      careDaysCollection,
      where("facilityId", "==", facilityId)
    );
    const careDaysQuerySnapshot = await getDocs(careDaysQuery);

    const careDayPromises = careDaysQuerySnapshot.docs.map((doc) => {
      const subCollectionCountPromises = CD_SUB_COLLECTION_NAMES.map(
        (subCollectionName) => {
          const subCollection = collection(
            firestore,
            careDayDatabase,
            doc.id,
            subCollectionName
          );
          return getCountFromServer(subCollection).then((snapshot) => ({
            [subCollectionName]: snapshot.data().count,
          }));
        }
      );

      return Promise.all(subCollectionCountPromises).then(
        (subCollectionCounts) => {
          const subCollectionCount = subCollectionCounts.reduce(
            (acc, curr) => ({
              ...acc,
              ...curr,
            }),
            {}
          );
          return {
            id: doc.id,
            ...doc.data(),
            subCollectionCount,
          };
        }
      );
    });

    return Promise.all(careDayPromises);
  };

  const getCareDayCollection = async (
    careDayId,
    collectionName,
    countSubCollectionNames
  ) =>
    await getCareDayCollectionPath(
      careDayId,
      [collectionName],
      countSubCollectionNames
    );

  const getCareDayCollectionPath = async (
    careDayId,
    collectionPath,
    countSubCollectionNames
  ) => {
    const collectionRef = collection(
      firestore,
      careDayDatabase,
      careDayId,
      ...collectionPath
    );
    const querySnapshot = await getDocs(collectionRef);

    const dataPromises = querySnapshot.docs
      .map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
      .map((data) => {
        if (countSubCollectionNames) {
          const subCollectionCountPromises = countSubCollectionNames.map(
            (subCollectionName) => {
              const subCollection = collection(
                firestore,
                careDayDatabase,
                careDayId,
                ...collectionPath,
                data.id,
                subCollectionName
              );
              return getCountFromServer(subCollection).then((snapshot) => ({
                [subCollectionName]: snapshot.data().count,
              }));
            }
          );

          return Promise.all(subCollectionCountPromises).then(
            (subCollectionCounts) => {
              const subCollectionCount = subCollectionCounts.reduce(
                (acc, curr) => ({
                  ...acc,
                  ...curr,
                }),
                {}
              );
              return {
                ...data,
                subCollectionCount,
              };
            }
          );
        }

        return Promise.resolve(data);
      });

    return Promise.all(dataPromises);
  };
  const getDocumentById = async (careDayId, collectionName, documentId) => {
    const docRef = doc(
      firestore,
      careDayDatabase,
      careDayId,
      collectionName,
      documentId
    );

    try {
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        // Document found, return its data
        return {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        };
      } else {
        // Document not found
        console.error(
          `Document with ID ${documentId} not found in collection ${collectionName}`
        );
        return null;
      }
    } catch (error) {
      console.error("Error getting document:", error);
      throw error; // You can handle the error according to your application's needs
    }
  };
  const getSpecificContactTeam = async (careDayFacilityId, teamId) => {
    const employeeCollectionRef = collection(
      firestore,
      facilityDatabase,
      careDayFacilityId,
      "employees"
    );
    const employeesInTeamQuery = query(
      employeeCollectionRef,
      where("contactTeamIds", "array-contains", teamId)
    );

    return await getDocs(employeesInTeamQuery).then((querySnapshot) =>
      querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))
    );
  };

  const removeEmployeeFromTeam = async (employeeId, teamId, facilityId) => {
    const employeeDocRef = doc(
      firestore,
      facilityDatabase,
      facilityId,
      "employees",
      employeeId
    );
    const employeeDoc = await getDoc(employeeDocRef);
    const employeeData = employeeDoc.data();
    if (employeeData.contactTeamIds.includes(teamId)) {
      const index = employeeData.contactTeamIds.indexOf(teamId);
      if (index !== -1) {
        employeeData.contactTeamIds.splice(index, 1);
        await setDoc(employeeDocRef, { ...employeeData });
      }
      return true;
    }
    return false;
  };

  const getMenuForWeek = async (careDayId, week, year) => {
    const collectionRef = collection(
      firestore,
      careDayDatabase,
      careDayId,
      "menu"
    );
    const weekQuery = query(
      collectionRef,
      where("week", "==", week),
      where("year", "==", year)
    );
    const querySnapshot = await getDocs(weekQuery);
    const docs = querySnapshot.docs;

    if (docs.length !== 1) {
      return null;
    }

    const doc = docs[0];
    return {
      id: doc.id,
      ...doc.data(),
    };
  };

  const getFood = async (facilityId) => {
    const collectionRef = collection(
      firestore,
      facilityDatabase,
      facilityId,
      "food"
    );
    const querySnapshot = await getDocs(collectionRef);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const getActivityTemplates = async (facilityId) => {
    const collectionRef = collection(
      firestore,
      facilityDatabase,
      facilityId,
      "activityTemplates"
    );
    const querySnapshot = await getDocs(collectionRef);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const getFoodTemplates = async (facilityId) => {
    const collectionRef = collection(
      firestore,
      facilityDatabase,
      facilityId,
      "foodTemplates"
    );
    const querySnapshot = await getDocs(collectionRef);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  const getEmployeesByContactTeam = async (teamId) => {
    try {
      const facilitiesCollection = collection(firestore, facilityDatabase).path;
      const currentCareDay = useCurrentCareDay();
      const facilityId =
        currentCareDay &&
        currentCareDay.value &&
        currentCareDay.value.facilityId;
      const employeesCollection = "employees";

      const employeesCollectionRef = collection(
        firestore,
        facilitiesCollection,
        facilityId,
        employeesCollection
      );
      const queryByContactTeam = query(
        employeesCollectionRef,
        where("contactTeamIds", "array-contains", teamId)
      );
      const employeesQuerySnapshot = await getDocs(queryByContactTeam);

      const employees = employeesQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      return employees;
    } catch (error) {
      return [];
    }
  };
  const getEmployeesCountByContactTeam = async (teamId) => {
    try {
      const facilitiesCollection = collection(firestore, facilityDatabase).path;

      const currentCareDay = useCurrentCareDay();
      const facilityId =
        currentCareDay &&
        currentCareDay.value &&
        currentCareDay.value.facilityId;
      const employeesCollection = "employees";

      const employeesCollectionRef = collection(
        firestore,
        facilitiesCollection,
        facilityId,
        employeesCollection
      );
      const queryByContactTeam = query(
        employeesCollectionRef,
        where("contactTeamIds", "array-contains", teamId)
      );
      const employeesQuerySnapshot = await getDocs(queryByContactTeam);
      const employeesCount = employeesQuerySnapshot.size;

      return employeesCount;
    } catch (error) {
      return 0;
    }
  };

  return {
    provide: {
      getUser,
      getFacility,
      getCareDays,
      getCareDayCollection,
      getCareDayCollectionPath,
      getMenuForWeek,
      getFood,
      getEmployees,
      getActivityTemplates,
      getFoodTemplates,
      getCareDayTeams,
      assignEmployeeToGroup,
      removeEmployeeFromPool,
      getSpecificContactTeam,
      removeEmployeeFromTeam,
      addEmployee,
      removeEmployee,
      getEmployeesByContactTeam,
      getEmployeesCountByContactTeam,
      getDocumentById,
    },
  };
});
