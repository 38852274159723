export default defineNuxtRouteMiddleware((to) => {
  if (to.path.startsWith("/careday") || to.path.startsWith("/kiosk")) {
    const careDayId = to.params.id;

    updateCurrentCareDay(careDayId);

    const careDayStore = useCareDayStore();
    careDayStore.$subscribe((mutation) => {
      if (mutation.type === "direct") {
        // we can't use to.params.id here, because it won't be updated after we subscribe
        const route = useRoute();
        const currentRouteId = route.params.id;
        updateCurrentCareDay(currentRouteId);
      }
    });
  }
});

const updateCurrentCareDay = (careDayId) => {
  const careDayStore = useCareDayStore();
  const currentCareDay = useCurrentCareDay();
  const storedCareDay = careDayStore.getCareDayById(careDayId);

  if (storedCareDay) {
    currentCareDay.value = storedCareDay;
  }
};
