import { User } from "firebase/auth";
import { Ref } from "vue";
import { RouteLocationNormalized } from "vue-router";
import { useUserStore } from "~/stores/UserStore";

export default defineNuxtRouteMiddleware((to) => {
  const { $auth } = useNuxtApp() as any;

  const firebaseUser: Ref<User | null> = useFirebaseUser();
  const cookieUser = useCookie("userCookie");
  const user = $auth.currentUser || firebaseUser.value || cookieUser.value as any as User;
  const userIsLoggedIn = isUserLoggedIn(user);

  if (userIsLoggedIn) {
    setSentryFirebaseUser(user);

    firebaseUser.value = user;
    cookieUser.value = user as any as string;
    useUserStore().getUserFromDb();
  } else {
    unsetSentryUser();
    return handleUserNotLoggedIn(to);
  }
});

const isUserLoggedIn = (user: User) => !!user?.uid;

const isDevicePath = (to: RouteLocationNormalized) => {
  const isPreview = !!to.query.preview;
  return to.path.startsWith("/kiosk") && !isPreview;
};

const handleUserNotLoggedIn = (to: RouteLocationNormalized) => {
  if (isDevicePath(to)) {
    return handleDeviceAuth(to);
  } else {
    return handleDashboardAuth();
  }
};

const handleDashboardAuth = () => {
  initUser();
};

const handleDeviceAuth = (to: RouteLocationNormalized) => {
  const token = to.query.token as string;

  if (token) {
    if (!(to.path === "/kiosk/login")) {
      const careDayId = to.params.id as string;

      setSentryCareDayID(careDayId);

      return navigateTo({
        path: "/kiosk/login",
        query: {
          token,
          careDayId
        }
      });
    }
  } else {
    unsetSentryCareDayID();

    if (!(to.path === "/kiosk/login")) {
      return createError({
        statusCode: 401,
        statusMessage: "Unauthorized"
      });
    }
  }
};
